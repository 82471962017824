import React from "react"

const Bio = () => {
  return (
    <div id="main-header">
      <h1>Hi there! I'm Nicolae Vartolomei.</h1>
      <p>
        Also on{" "}
        <a
          href="https://twitter.com/nvartolomei"
          target="_blank"
          rel="noreferrer"
        >
          Twitter
        </a>
        ,{" "}
        <a
          href="https://github.com/nvartolomei"
          target="_blank"
          rel="noreferrer"
        >
          GitHub
        </a>
        ,{" "}
        <a
          href="https://www.linkedin.com/in/nvartolomei/"
          target="_blank"
          rel="noreferrer"
        >
          LinkedIn
        </a>
        , and at <a href="mailto:nv@nvartolomei.com">nv@nvartolomei.com</a>.
      </p>
    </div>
  )
}

export default Bio
